
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

const AVAILABLE_FIELDS = ["C", "ST", "L", "O", "CN"];

@Component({
  components: {},
})
export default class Issuers extends Vue {
  protected name = "Issuers";

  @Prop({ required: true })
  public issuer!: any;

  protected selectedIssuerShort = "";
  protected mIssuer: any = {};

  @Watch("issuer", { immediate: true, deep: true })
  protected onIssuersChanged(issuer: any) {
    if (issuer) {
      this.mIssuer = issuer;
    }
  }

  protected get possibleIssuerShorts(): Array<string> {
    let usedIssuerShorts: Array<string> = this.issuer
      ? Object.keys(this.issuer)
      : [];
    return AVAILABLE_FIELDS.filter((issuerShort: string) => {
      return !usedIssuerShorts.includes(issuerShort);
    });
  }
}
