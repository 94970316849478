
import { Component, Model, Prop, VModel, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Domains extends Vue {
  protected name = "Domains";

  @VModel()
  protected domains!: any;

  /*
  @Prop({ required: true })
  public domains!: any;

   */
}
