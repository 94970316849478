
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";

const AVAILABLE_SUBJECTS = ["C", "ST", "L", "O", "OU", "CN"];

@Component({
  components: {},
})
export default class Subjects extends Vue {
  protected name = "Subjects";

  @VModel({ required: true })
  public subjects!: any;

  protected selectedSubjectShort = "";
  protected mSubjects: any = {};

  @Watch("subjects", { immediate: true, deep: true })
  protected onSubjectsChanged(subjects: any) {
    if (subjects) {
      this.mSubjects = subjects;
    }
  }

  protected get possibleSubjectShorts(): Array<string> {
    let usedSubjectShorts: Array<string> = this.subjects
      ? Object.keys(this.subjects)
      : [];
    return AVAILABLE_SUBJECTS.filter((subjectShort: string) => {
      return !usedSubjectShorts.includes(subjectShort);
    });
  }
}
