
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AppConfig from "@/config/AppConfig";
import Domains from "@/components/ssl_certs/csr_create/Domains.vue";
import ISslCertificate from "@/interfaces/ISslCertificate";
import Subjects from "@/components/ssl_certs/csr_create/Subjects.vue";
import Issuer from "@/components/ssl_certs/csr_create/Issuer.vue";
import { mixins } from "vue-class-component";
import { SslCertStoreMixin } from "@/mixins/Stores/SslCertStoreMixin";

const SslCertModule = namespace(AppConfig.SSL_CERTIFICATE_STORE);

@Component({
  components: { Issuer, Subjects, Domains },
})
export default class SslCertCsrCreate extends mixins(SslCertStoreMixin) {
  protected name = "SslCertCsrCreate";

  protected domains: Array<string> = [];
  protected issuer: any = {};
  protected subjects: any = {};

  protected csrCreateVerify: any = null;

  public get resource(): string {
    return AppConfig.SSL_CERTIFICATE_RESOURCE;
  }

  public get descriptionField(): string {
    return "domain_id";
  }

  protected get route(): any {
    return this.$route;
  }

  protected get sslCert(): ISslCertificate {
    return this.getSslCertItem;
  }

  @Watch("route", { immediate: true, deep: true })
  protected onRouteChanged(route: any): void {
    if (route.params && route.params.id) {
      this.loadSslCertById(route.params.id);
    }
  }

  @Watch("sslCert", { immediate: true, deep: true })
  protected onSslCertChanged(sslCert: ISslCertificate) {
    if (sslCert) {
      this.domains = sslCert.domains ?? [];
      this.issuer = sslCert.raw_ssl_certificate_fields
        ? sslCert.raw_ssl_certificate_fields.issuer
        : {};
      this.subjects = sslCert.raw_ssl_certificate_fields
        ? { ...sslCert.raw_ssl_certificate_fields.subject }
        : {};
    }
  }

  protected loadSslCertById(id: number): void {
    this.findOneSslCertItemAction({
      resource: this.resource,
      id: id,
    });
  }

  protected onAddDomain(): void {
    this.domains.push("");
  }

  protected onRemoveDomain(domainIdx: number) {
    this.domains.splice(domainIdx, 1);
  }

  protected onAddSubject(subjectShort: string) {
    let subjects = { ...this.subjects };
    this.subjects = {};
    subjects[subjectShort] = "";
    this.subjects = subjects;
  }

  protected onRemoveSubject(subjectShort: string) {
    let subjects = { ...this.subjects };
    delete subjects[subjectShort];
    this.subjects = [];
    this.subjects = subjects;
  }

  protected onAddIssuerField(issuerFieldShort: string): void {
    let issuer = { ...this.issuer };
    this.issuer = {};
    issuer[issuerFieldShort] = "";
    this.issuer = issuer;
  }

  protected onRemoveIssuerField(issuerShort: string) {
    let issuer = { ...this.issuer };

    delete issuer[issuerShort];
    this.issuer = [];
    this.issuer = issuer;
  }

  protected onCreateNewCsrClick(): void {
    this.createNewCsr();
  }

  protected createNewCsr(): void {
    const csrMeta: any = {
      domains: this.domains,
      subjects: this.subjects,
      issuer: this.issuer,
    };

    this.csrCreateVerify = null;
    this.createNewSslCertCsrAction({
      data: csrMeta,
    }).then((response: any) => {
      this.csrCreateVerify = response.data;

      this.$buefy.dialog.confirm({
        type: "is-success",
        message: String(
          this.$t(
            "modules.ssl_certs.msg_confirm_redirect_after_successful_create"
          )
        ),
        onConfirm: () => {
          this.$router.push({
            name: "SslCertificateUpdate",
            params: { id: this.getSslCertItem.id },
          });
        },
      });
    });
  }
}
